<template>
  <div class="bottom-bar">
    <BottomBarItem v-for="item in navigationPoints" :key="item.id" :item="item" @click="handleClick(item.name)" />
  </div>
</template>

<script>
import Compass from "@/assets/icons/BottomBar/compass.svg";
import Categories from "@/assets/icons/BottomBar/categories.svg";
import Search from "@/assets/icons/BottomBar/search.svg";
import Heart from "@/assets/icons/BottomBar/heart.svg";
import QrCode from "@/assets/icons/BottomBar/qrcode.svg";

import BottomBarItem from "@/components/home/BottomBarItem";

export default {
  name: "BottomBar",
  components: {
    BottomBarItem,
  },
  data() {
    return {
        navigationPoints: [
            {
                id: 1,
                name: "Entdecken",
                icon: Compass,
                active: true,
            },
            {
                id: 2,
                name: "Kategorien",
                icon: Categories,
                active: false,
            },
            {
                id: 3,
                name: "Suche",
                icon: Search,
                active: false,
            },
            {
                id: 4,
                name: "Gespeichert",
                icon: Heart,
                active: false,
            },
            {
                id: 5,
                name: "Angebote",
                icon: QrCode,
                active: false,
            }
        ]
    };
  },
  methods: {
    handleClick(name) {
      // Alle Elemente auf active-false setzen, außer das angeklickte.
      this.navigationPoints.forEach(item => {
        item.active = item.name === name;
      });

      this.$emit('navigate', name);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding:10px 0 20px;
  background-color: $white;
  z-index: 1;
}

</style>
