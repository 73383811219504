<template>
  <div class="active-card">
    <div class="icon">
      <svg viewBox="0 0 180 146">
        <use :xlink:href="activeCard.icon + '#onboarding'"></use>
      </svg>
    </div>
    <div class="title">{{ activeCard.title }}</div>
    <div class="text">{{ activeCard.desc }}</div>
  </div>
</template>

<script>
export default {
    name: "OnboardingItem",
    props: {
        activeCard: Object
    }
};
</script>

<style lang="scss" scoped>

.icon {
  width: 400px;
  height: 220px;
  max-width: 100%;
  max-height: 50vh;
}

svg {
  width: 100%;
  height: 100%;
}

.title {
  font-size: 1.4rem;
  line-height: 1.3;
  font-weight: 700;
  text-align: center;
  width: 200px;
  max-width: 100%;
  margin:30px auto 10px auto;
}

.text {
  text-align: center;
  width: 300px;
  max-width: 100%;
  margin:0 auto;
}

</style>
