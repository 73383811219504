<template>
  <div class="top-bar">
    <div>A</div>

    <div class="logo">
      <svg viewBox="0 0 200 60">
        <use :xlink:href="`${logo}#logo`"></use>
      </svg>
    </div>

    <div>A</div>
  </div>
</template>

<script>
import Logo from "@/assets/img/main/logo.svg";

export default {
  name: "TopBar",
  data() {
    return {
      logo: Logo,
    };
  },
  mounted() {
    console.log("Store State:", this.$store.state);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 15px;
  background-color: $white;
  background-color: $white;
  z-index: 1;

  .logo {
    width: auto;
    height: auto;
    line-height: 0;

    svg {
      width: auto;
      height: 30px;
    }
  }
}
</style>
