<template>
  <div>
    <Form
      class="mail-login"
      @submit="submitData"
      :validation-schema="schema"
      v-slot="{ errors }"
    >
      <div class="alert alert-danger" v-if="error" key="error-message">
        {{ errorDisplayText }}
      </div>

      <div class="mail-input" key="mail-input">
        <label class="form-label" for="mail">Mit E-Mail-Adresse anmelden</label>

        <Field
          as="input"
          class="form-input"
          id="email"
          name="email"
          type="text"
          placeholder="E-Mail-Adresse"
          @keydown.enter.prevent="checkEmail"
        />
        <small class="text-danger" v-if="errors.email">{{
          errors.email
        }}</small>
      </div>

      <div
        class="password-input mt-15"
        key="password-input"
        v-if="showPasswordInput"
      >
        <label class="form-label" for="password">Passwort</label>
        <Field
          as="input"
          class="form-input"
          id="password"
          name="password"
          type="password"
          placeholder="Passwort"
        />
        <small class="text-danger" v-if="errors.password">{{
          errors.password
        }}</small>
      </div>

      <button
        :type="showPasswordInput ? 'submit' : 'button'"
        @click="checkEmail"
        class="bg-primary text-white mt-15"
        :disabled="errors && errors.length > 0 && showPasswordInput"
      >
        <span v-if="!isLoading && !showPasswordInput">Weiter</span>
        <span v-else-if="!isLoading && showPasswordInput">Anmelden</span>
        <span v-else class="spinner"></span>
      </button>
    </Form>

    <transition name="fade" @after-leave="showPassword">
      <div class="social-login" v-if="!hideSocialLogin">
        <div class="hr-or">
          <span>oder</span>
        </div>

        <div class="social-login">
          <button class="apple text-white">Weiter mit Apple</button>
          <button class="google text-white mt-15">Weiter mit Google</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { Form, Field } from "vee-validate";
import * as yup from "yup";

export default {
  name: "LoginComponent",
  components: {
    Form,
    Field,
  },
  data() {
    const schema = yup.object().shape({
      email: yup
        .string()
        .required("Bitte gib eine gültige E-Mail-Adresse ein.")
        .trim()
        .email("Bitte gib eine gültige E-Mail-Adresse ein."),
      password: yup
        .string()
        .required("Ein Passwort wird benötigt.")
        .min(6, "Das Passwort muss mindestens sechs Zeichen lang sein."),
    });
    return {
      schema,
      hideSocialLogin: false,
      showPasswordInput: false,
      error: "",
      isLoading: false,
    };
  },
  methods: {
    checkEmail() {
      this.hideSocialLogin = true;
    },
    showPassword() {
      this.showPasswordInput = true;

      this.$nextTick(() => {
        const passwordInput = document.getElementById("password");
        if (passwordInput) passwordInput.focus();
      });
    },
    submitData(values) {
      this.isLoading = true;
      this.error = "";

      this.$store
        .dispatch("signInUp", {
          email: values.email,
          password: values.password,
        })
        .then((resp) => {
          this.isLoading = false;


          this.$router.push("/home");
        })
        .catch((error) => {
          this.error = error.message;
          this.isLoading = false;
        });
    },
  },
  computed: {
    errorDisplayText() {
      if (this.error) {
        if (this.error.includes("INVALID_LOGIN_CREDENTIALS")) {
          return "Die E-Mail-Adresse oder das Passwort ist nicht korrekt. Bitte versuche es erneut.";
        } else if (this.error.includes("USER_DISABLED")) {
          return "Das Benutzerkonto wurde von einem Administrator deaktiviert. Bei Problemen wende dich bitte an unseren Kundenservice unter support@nirby.de.";
        } else if (
          this.error.includes(
            "TOO_MANY_ATTEMPTS_TRY_LATER : Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later."
          )
        ) {
          return "Hoppla! Das Konto ist aufgrund von wiederholten fehlerhaften Loginversuchen vorübergehend gesperrt. Setze dein Passwort zurück oder probiere es später erneut.";
        } else if (this === "TOO_MANY_ATTEMPTS_TRY_LATER") {
          return "Wir verzeichnen ungewöhnliche Aktivitäten von diesem Gerät. Bitte versuche es später erneut.";
        } else {
          return "Es ist ein unbekannter Fehler aufgetreten. Bitte versuche es nocheinmal oder wende dich an unseren Kundenservice unter support@nirby.de.";
        }
      }

      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.mail-login {
  label {
    text-align: center;
    margin: 0 0 5px;
  }

  input {
    text-align: center;
    padding: 11px 10px;
  }

  label,
  input,
  div {
    transition: 0.5s ease-in-out;
  }
}

.hr-or {
  margin: 50px 0;
  text-align: center;
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: $md-grey;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
  }

  span {
    display: inline-block;
    background-color: $white;
    position: relative;
    z-index: 1;
    padding: 0 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 0.9rem;
    font-weight:400;
    color: $md-grey;
  }
}

.social-login {
  button {
    font-weight: 400;

    &.apple {
      background-color: $black;
    }

    &.google {
      border: 1px solid $black;
      color: $black;
    }
  }
}

/* Fade-Übergang für Social-Login-Buttons */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Slide-Fade-Übergang für Passwort-Eingabefeld */
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>
