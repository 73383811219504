import axios from "axios";
import { FIREBASE_API_KEY } from "@/config/firebase";

const state = {
  address: null,
  userId: null,
  token: null,
};

const mutations = {
  setAddress(state, payload) {
    state.address = payload.address;
  },
  setUser(state, payload) {
    state.userId = payload.userId;
    state.token = payload.token;
  },
  setFromLocalStorage(state, payload){
    state.address = payload.address;
    state.userId = payload.userId;
    state.token = payload.token;
  }
};

const actions = {
  saveAddress(context, payload) {
    context.commit("setAddress", {
      address: payload.address,
    });


    localStorage.setItem("address", payload.address);
  },

  signInUp(context, payload) {
    const userDO = {
      email: payload.email,
      password: payload.password,
      returnSecureToken: true,
    };

    const signInURL = `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${FIREBASE_API_KEY}`;
    const signUpURL = `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${FIREBASE_API_KEY}`;

    return axios
      .post(signUpURL, userDO)
      .then((response) => {
        // Registrierung erfolgreich
        localStorage.setItem("token", response.data.idToken);
        localStorage.setItem("user", response.data.localId);

        context.commit("setUser", {
          userId: response.data.localId,
          token: response.data.idToken,
        });
      })
      .catch((error) => {
        // Wenn die E-Mail existiert, probiere einzuloggen
        if (error.response.data.error.message === "EMAIL_EXISTS") {
          return axios
            .post(signInURL, userDO)
            .then((response) => {
              // Login erfolgreich
              localStorage.setItem("token", response.data.idToken);
              localStorage.setItem("user", response.data.localId);
              
              context.commit("setUser", {
                userId: response.data.localId,
                token: response.data.idToken,
              });
            })
            .catch((error) => {
              const errorMessage = new Error(
                error.response.data.error.message || "UNKNOWN_ERROR"
              );
              throw errorMessage;
            });
        } else {
          const errorMessage = new Error(
            error.response.data.error.message || "UNKNOWN_ERROR"
          );
          throw errorMessage;
        }
      });
  },
  initStore({ commit }){
    const stateDO = {
      address: localStorage.getItem('address') || null,
      userId: localStorage.getItem('user') || null,
      token: localStorage.getItem('token') || null,
    }

    if (stateDO) commit('setFromLocalStorage', stateDO);
  }
};

const getters = {};

const authModule = {
  state,
  mutations,
  actions,
  getters,
};

export default authModule;
