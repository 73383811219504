<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  created() {
    this.$store.dispatch("initStore");

    if (this.$store.state.auth.userId && this.$store.state.auth.token) {
      this.$router.push("/home");
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";


* {
  box-sizing: border-box;
}

html, body{
  height:100vh;
  overflow-y: hidden;
  overflow-x: hidden;
}

html {
  height: 100vh;
  width: 100vw;
  touch-action: manipulation;
  overflow-x: hidden;
  user-select: none;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
  background-color: $white;
  color: $black;
}

#app {
  height: 100%;
}

.container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
  overflow: hidden;
}

// Headlines
h1 {
  font-size: 1.8rem;
  line-height: 1.3;
  font-weight: 700;
  margin: 0 0 15px;
}

h2 {
  font-size: 1.5rem;
  line-height: 1.3;
  font-weight: 700;
  margin: 0 0 15px;
}

h3 {
  font-size: 1.2rem;
  line-height: 1.3;
  font-weight: 700;
  margin: 0 0 15px;
}

button {
  border: none;
  box-shadow: none;
  outline: none;
  padding: 12px 24px;
  font-size: 1rem;
  width: 100%;
  display: block;
  border-radius: $border-radius;
  border-radius: 100px;
  font-weight: 600;
}

button,
input,
textarea,
select,
label {
  font-family: inherit;
}

.mt-15 {
  margin-top: 15px;
}

.form-element + .form-element {
  margin-top: 30px;
}

.form-label {
  font-size: 0.9rem;
  font-weight: 600;
  width: 100%;
  display: block;
  margin: 0 0 2px;
}

.form-input {
  font-size: 1rem;
  width: 100%;
  display: block;
  outline: none !important;
  background-color: $white;
  color: $black;
  border: 1px solid $md-grey;
  padding: 15px 15px;
  border-radius: $border-radius;
}

.range-container {
  width: 100%;
  padding: 10px 0;

  .form-range {
    -webkit-appearance: none;
    width: 100%;
    height: 6px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }

  .form-range:hover {
    opacity: 1;
  }

  .form-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: $base-color;
    border: none;
    box-shadow: none;
    cursor: pointer;
  }

  .form-range::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: $base-color;
    border: none;
    box-shadow: none;
    cursor: pointer;
  }
}

.form-range {
  width: 100%;
  display: block;
}

.bg-primary {
  background-color: $base-color;
}

.text-white {
  color: $white;
}

.text-danger {
  color: $danger;
  font-size: 0.9rem;
}

.alert {
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid;
  border-radius: $border-radius;
  font-size: 0.9rem;

  &.alert-danger {
    color: $white;
    background-color: $danger;
    border-color: $danger;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.spinner {
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  vertical-align: text-bottom;
  border: 0.15em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 1s linear infinite; /* Safari und Chrome */
  -moz-animation: spinner-border 1s linear infinite; /* Firefox */
  -o-animation: spinner-border 1s linear infinite; /* Opera */
  animation: spinner-border 1s linear infinite; /* Standard */
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
</style>
