<template>
    <div>
        Angebote

    </div>
</template>

<script>
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "Offers",
    }
</script>

<style lang="scss" scoped>

</style>