<template>
  <div class="content">
    <div class="inner">
      <h1>Wo möchtest Du Neues entdecken?</h1>
      <div class="text">
        Neugierig, was in Deiner Nähe los ist? Trag Deine PLZ ein und wähle
        Deinen Umkreis, in dem Du stöbern möchtest.
      </div>

      <form>
        <div class="form-element">
          <label for="plz" class="form-label">Postleitzahl</label>
          <div class="auto-input">
            <div class="input">
              <input
                class="form-input"
                type="text"
                id="plz"
                name="plz"
                placeholder="Postleitzahl eingeben"
                min="5"
                max="5"
                :value="address.plz"
                @input="fetchAutocomplete"
                @focusin="showInput"
                @keydown.enter.prevent="hideInput"
              />
              <span @click="hideInput">Abbrechen</span>
            </div>
            <div class="dropdown">
              <div class="dropdown-headline">Vorschläge</div>

              <div
                class="autocomplete-result"
                v-for="result in autocompleteResults"
                :key="result.terms[0]"
              >
                <AutocompleteResult
                  :result="result"
                  @click="chooseThisResult(result)"
                />
              </div>
            </div>
          </div>
          <div @click="getLocation" class="getLocation">
            Aktuellen Standort verwenden
          </div>
        </div>
        <div class="form-element">
          <label for="range" class="form-label"
            >Bestimme deinen Suchradius</label
          >

          <div class="range-container">
            <input
              class="form-range"
              type="range"
              id="range"
              name="range"
              min="5"
              max="50"
              value="15"
              step="5"
              @input="changeRange"
            />
            <p>
              <span id="range-value">{{ rangeValue }} km</span>
            </p>
          </div>
        </div>

        <div class="form-submit">
          <button
            class="btn-submit bg-primary text-white"
            @click="changeComponent('LoginComponent')"
            type="button"
          >
            Los gehts!
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
/* eslint-disable */

import AutocompleteResult from "@/components/Onboarding/AutocompleteResult.vue";

export default {
  name: "AddressInput",
  components: {
    AutocompleteResult,
  },
  data() {
    return {
      rangeValue: 15,
      address: { range: 15 },
      plz: "",
      autocompleteResults: [],
      autocompleteService: null,
    };
  },
  emits: {
    "change-component": (componentName) => {
      if (componentName !== "LoginComponent") {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.loadGooglePlacesScript();
  },
  methods: {
    changeRange() {
      this.rangeValue = document.querySelector("#range").value;

      this.address.range = this.rangeValue;

      this.$store
        .dispatch("saveAddress", {
          address: this.address,
        })
        .then(() => {})
        .catch((error) => {
          console.log(error.message);
        });
    },
    getLocation() {
      if (!("geolocation" in navigator)) {
        alert("Geolocation is not available.");
        return;
      }

      // get position
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // console.log(position);
          // console.log(position.coords.latitude);
          // console.log(position.coords.longitude);
          // Google Geocoding API
          this.getStreetAddressFrom(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (error) => {
          alert(error.message);
        }
      );
    },
    async getStreetAddressFrom(lat, long) {
      try {
        var { data } = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            lat +
            "," +
            long +
            "&key=AIzaSyCY0rXFk2n5lWmBGDI7yzWFtK6BfZZQqDw"
        );

        if (data.error_message) {
          console.log(data.error_message);
        } else {
          const addressData = data.results[0].formatted_address;
          let addressArray = addressData.replace(/ /g, ",");
          addressArray = addressArray.replace(/,,/g, ",");
          addressArray = addressArray.split(",");

          this.address.street = addressArray[0];
          this.address.streetnr = addressArray[1];
          this.address.plz = addressArray[2];
          this.address.city = addressArray[3];
          this.address.country = addressArray[4];
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    loadGooglePlacesScript() {
      if (!window.google || !window.google.maps) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCY0rXFk2n5lWmBGDI7yzWFtK6BfZZQqDw&libraries=places&callback=initAutocomplete`;
        script.async = true;
        script.defer = true;

        window.initAutocomplete = this.initAutocomplete;

        document.head.appendChild(script);
      } else {
        this.initAutocomplete();
      }
    },
    initAutocomplete() {
      this.autocompleteService = new google.maps.places.AutocompleteService();
    },
    fetchAutocomplete() {
      this.plz = plz.value;
      this.address.plz = plz.value;

      this.$store
        .dispatch("saveAddress", {
          address: this.address,
        })
        .then(() => {})
        .catch((error) => {
          console.log(error.message);
        });

      if (this.plz && this.autocompleteService) {
        const request = {
          input: this.plz,
          componentRestrictions: { country: "de" },
          types: ["(regions)"],
        };

        this.autocompleteService.getPlacePredictions(
          request,
          (predictions, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              this.autocompleteResults = predictions;
            }
          }
        );
      } else {
        this.autocompleteResults = [];
      }
    },
    selectAutocomplete(item) {
      this.plz = item.description;
      this.autocompleteResults = [];
    },
    showInput() {
      const autoInput = document.querySelector(".auto-input");

      autoInput.classList.add("in");

      const pos = autoInput.getBoundingClientRect();

      //autoInput.style.transform = "translateY(-" + (pos.top - 10) + "px)";
    },
    hideInput() {
      const autoInput = document.querySelector(".auto-input");
      autoInput.classList.remove("in");
      autoInput.style.transform = "";
    },
    chooseThisResult(result) {
      const val = result.terms[0].value;

      this.plz = val;
      this.address.plz = val;

      this.$store
        .dispatch("saveAddress", {
          address: this.address,
        })
        .then(() => {
          this.hideInput();
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    changeComponent(componentName) {
      this.$emit("change-component", { componentName });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.content {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 20px;
  position: relative;
  z-index: 1;
  background-color: $white;
}

form {
  margin-top: 30px;
}

button {
  margin-top: 30px;
}

.auto-input {
  background-color: $white;
  transition: 0.35s ease-in-out;

  .input {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: 0.35s ease-in-out;

    span {
      width: 0;
      overflow: hidden;
      font-size: 0.8rem;
      text-align: right;
      font-weight: 600;
    }
  }

  input,
  div,
  span {
    transition: 0.35s;
  }

  .dropdown {
    height: 0;
    overflow: hidden;

    .dropdown-headline {
      font-size: 0.9rem;
      font-weight: 600;
      margin: 15px 0 0;
      padding: 15px 0;
      border-bottom: 1px solid $lg-grey;
    }
  }

  &.in {
    margin: 0 -40px;
    padding: 10px 15px;

    .input {
      margin-top: 30px;

      input {
        width: calc(100% - 100px);
      }

      span {
        width: 100px;
      }
    }

    .dropdown {
      height: calc(100vh - 48px - 30px);
      padding: 10px 0;
    }
  }
}
.getLocation {
  color: $base-color;
  font-weight: 600;
  font-size: 0.9rem;
  text-align: center;
  margin: 10px 0 0;
}
</style>
