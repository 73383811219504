<template>
  <div class="result">
    <span class="plz" v-if="result.terms[0]"> {{ result.terms[0].value }}, </span>
    <span class="city" v-if="result.terms[1]"> {{ result.terms[1].value }}, </span>
    <span class="country" v-if="result.terms[2]">
      {{ result.terms[2].value }}
    </span>
  </div>
</template>

<script>
export default {
  name: "AutocompleteResult",
  props: {
    result: Object,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.result {
  font-size: 0.9rem;
  padding: 10px 0;
  border-bottom: 1px solid $lg-grey;
}
</style>
