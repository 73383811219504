<template>
  <div class="bb-item" :class="{ active: item.active }">
    <div class="icon">
      <svg viewBox="0 0 180 146">
        <use :xlink:href="item.icon + '#icon'"></use>
      </svg>
    </div>

    <div class="title">
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomBarItem",
  props: {
    item: Object,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.bb-item {
  color: $md-grey;
  width: 20%;
  overflow: hidden;
  padding: 10px 2px;
  transition: 0.1s ease-in-out;

  &.active {
    color: $base-color;
  }

  .icon {
    height: 20px;
    transition: 0.1s ease-in-out;

    svg {
      width: 100%;
      height: 100%;

      use {
        widows: 100%;
        height: 100%;
      }
    }
  }
  .title {
    font-size: 11px;
    letter-spacing: -0.25px;
    line-height: 17px;
    text-align: center;
    padding-top: 3px;
  }
}
</style>
