<template>
  <div class="home">
    <TopBar />

    <main>
      <Transition name="slide-fade">
        <component :is="activeComponent"></component>
      </Transition>
    </main>

    <BottomBar @navigate="setActiveComponent" />
  </div>
</template>

<script>
// @ is an alias to /src
import TopBar from "@/components/home/TopBar";
import BottomBar from "@/components/home/BottomBar";

// Content
import Entdecken from "@/components/home/explore/Explore";
import Kategorien from "@/components/home/categories/Categories";
import Suche from "@/components/home/search/Search";
import Gespeichert from "@/components/home/saved/Saved";
import Angebote from "@/components/home/offers/Offers";

export default {
  name: "HomeView",
  components: {
    TopBar,
    BottomBar,
    Entdecken,
    Kategorien,
    Suche,
    Gespeichert,
    Angebote,
  },
  data() {
    return {
      activeComponent: "Entdecken",
    };
  },
  methods: {
    setActiveComponent(componentName) {
      this.activeComponent = componentName;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

main {
  height: 100vh;
  padding:80px 0 90px;
  background-color: $lg-grey;
  overflow-y: scroll;
  overflow-x: hidden;
}

@supports (bottom: constant(safe-area-inset-bottom)) {
  .bottom-bar {
    padding-bottom: calc(20px + constant(safe-area-inset-bottom));
  }

  main {
    padding-bottom: calc(80px + constant(safe-area-inset-bottom));
    padding-top: calc(80px + constant(safe-area-inset-bottom));
  }
}

@supports (bottom: env(safe-area-inset-bottom)) {
  .bottom-bar {
    padding-bottom: calc(20px + env(safe-area-inset-bottom));
  }

  main {
    padding-bottom: calc(80px + env(safe-area-inset-bottom));
    padding-top: calc(80px + env(safe-area-inset-bottom));
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
  position: absolute;
}

/* Enter from, and Leave to */
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

/* Enter to, and Leave from */
.slide-fade-enter-to,
.slide-fade-leave-from {
  transform: translateX(0);
  opacity: 1;
}
</style>
