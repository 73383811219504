<template>
    <div>
        Entdecken

    </div>
</template>

<script>
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "Explore",
    }
</script>

<style lang="scss" scoped>

</style>