import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Vue3TouchEvents from 'vue3-touch-events'
import './assets/styles/_fonts.scss';

const app = createApp(App)
app.use(store);
app.use(router);
app.use(Vue3TouchEvents);
app.mount('#app');
