<template>
  <div class="container">
    <div class="logo">
      <svg viewBox="0 0 200 60" width="auto" height="40px">
        <use :xlink:href="`${logo}#logo`"></use>
      </svg>
    </div>

    <Transition name="slide-fade">
      <component
        :is="componentName"
        @change-component="changeComponent"
      ></component>
    </Transition>

    <div class="loadingscreen" :class="{ active: showLoadingscreen }">
      <span>Lass uns loslegen ...</span>
    </div>
  </div>
</template>

<script>
import AddressInput from "@/components/Onboarding/AddressInput.vue";
import LoginComponent from "@/components/auth/LoginComponent.vue";
import Logo from "@/assets/img/main/logo.svg";

export default {
  name: "LoginPage",
  components: {
    AddressInput,
    LoginComponent,
  },
  data() {
    return {
      showLoadingscreen: true,
      componentName: "AddressInput",
      logo: Logo,
    };
  },
  created() {
    setTimeout(() => {
      this.showLoadingscreen = false;
    }, 1000);
  },
  methods: {
    changeComponent(payload) {
      this.componentName = payload.componentName;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.logo {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  height: 100px;
  padding: 30px 0;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 40px;
  }
}

.loadingscreen {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $white;
  opacity: 0;
  visibility: hidden;
  transition: 0.85s ease-in-out;
  font-size: 48px;
  color: $black;
  font-weight: 700;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 20px;
  transition-delay: 1s;

  display: none !important; // Entfernen

  span {
    opacity: 0;
    visibility: hidden;
    transition: 0.85s ease-in-out;
  }

  &.active {
    opacity: 1;
    visibility: visible;

    span {
      opacity: 1;
      visibility: visible;
    }
  }
}

.container {
  height: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Enter and Leave Active */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
  position: absolute;
}

/* Enter from, and Leave to */
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

/* Enter to, and Leave from */
.slide-fade-enter-to,
.slide-fade-leave-from {
  transform: translateX(0);
  opacity: 1;
}
</style>
