<template>
  <div class="container">
    <div class="logo">
      <svg viewBox="0 0 200 60">
        <use :xlink:href="`${logo}#logo`"></use>
      </svg>
    </div>

    <div
      class="content"
      v-touch:swipe.left="nextCard"
      v-touch:swipe.right="previousCard"
    >
      <div class="inner">
        <div class="card-block">
          <Transition name="fade" mode="out-in">
            <OnboardingItem :key="activeCard.id" :activeCard="activeCard" />
          </Transition>
        </div>

        <div class="loader">
          <div
            class="loading-bar"
            v-for="card in OnboardingCards"
            :key="card.id"
            :class="{ active: card.active }"
          ></div>
        </div>
      </div>
    </div>

    <div class="actions">
      <div class="inner">
        <a @click="goToLogin">Überspringen</a>

        <button class="next" @click="nextCard" :disabled="btnDisabled">
          <svg viewBox="0 0 44 44">
            <use :xlink:href="this.arrowCircle + '#icon'"></use>
          </svg>
        </button>
      </div>
    </div>
  </div>

  <div class="loadingscreen" :class="{ active: showLoadingscreen }">
    <span>Lass uns loslegen ...</span>
  </div>
</template>

<script>
import Logo from "@/assets/img/main/logo.svg";
import OnboardingIcon_01 from "@/assets/img/Onboarding/Onboarding_01.svg";
import OnboardingIcon_02 from "@/assets/img/Onboarding/Onboarding_02.svg";
import OnboardingIcon_03 from "@/assets/img/Onboarding/Onboarding_03.svg";
import ArrowCircle from "@/assets/icons/arrow-circle.svg";

import OnboardingItem from "@/components/Onboarding/OnboardingItem.vue";

export default {
  name: "OnboardingPage",
  components: {
    OnboardingItem,
  },
  data() {
    return {
      OnboardingCards: [
        {
          id: 1,
          title: "Entdecke die Umgebung",
          desc: "Finde neue Orte und lokale Highlights, nur wenige Schritte von deinem Zuhause entfernt.",
          icon: OnboardingIcon_01,
          active: true,
        },
        {
          id: 2,
          title: "Sofort buchen, sofort genießen",
          desc: "Reserviere deinen Tisch im Lieblingsrestaurant oder buche Termine für ganz bequem in der App.",
          icon: OnboardingIcon_02,
          active: false,
        },
        {
          id: 3,
          title: "Spare mit jedem Besuch",
          desc: "Entdecke und sichere dir exklusive Rabatte und Coupons für deine Lieblingsorte.",
          icon: OnboardingIcon_03,
          active: false,
        },
      ],
      cardTimer: null,
      arrowCircle: ArrowCircle,
      btnDisabled: false,
      showLoadingscreen: false,
      logo: Logo,
    };
  },
  computed: {
    activeCard() {
      return this.OnboardingCards.find((card) => card.active);
    },
  },
  methods: {
    nextCard() {
      console.log("swipe left");
      const activeCard = this.OnboardingCards.findIndex((card) => card.active);

      if (this.OnboardingCards[activeCard + 1]) {
        this.OnboardingCards[activeCard].active = false;
        this.OnboardingCards[activeCard + 1].active = true;

        this.btnDisabled = true;

        setTimeout(() => {
          this.btnDisabled = false;
        }, 700);
      } else {
        this.goToLogin();
      }
    },
    previousCard() {
      const activeCard = this.OnboardingCards.findIndex((card) => card.active);

      if (this.OnboardingCards[activeCard - 1]) {
        this.OnboardingCards[activeCard].active = false;
        this.OnboardingCards[activeCard - 1].active = true;

        this.btnDisabled = true;

        setTimeout(() => {
          this.btnDisabled = false;
        }, 700);
      }
    },
    goToLogin() {
      this.showLoadingscreen = true;

      setTimeout(() => {
        this.$router.push("/login");
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.fade-enter-active,
.fade-leave-active {
  transition: all 0.35s ease-in-out !important;
}

.fade-enter-from {
  opacity: 0;
}
.fade-enter-to {
  transform: translateX(0);
  opacity: 1;
}

.fade-leave-from {
  transform: translateX(0);
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}

.container {
  height: 100%;
}

.logo {
  height: 100px;
  padding: 30px 0;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 40px;
  }
}

.content {
  height: calc(100% - 200px);
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;

  .loading-bar {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    border-radius: 10px;
    background-color: $lg-grey;
    position: relative;
    overflow: hidden;
    transition: 0.7s ease-in-out;

    &.active {
      background-color: $base-color;
    }
  }
}

.actions {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0 20px 30px;
  width: 100%;

  .inner {
    position: relative;

    a {
      color: $secondary-color;
      line-height: 44px;
      font-weight: 600;
      font-size: 0.8rem;
      text-decoration: none;
    }

    button {
      background-color: $secondary-color;
      position: fixed;
      bottom: -35vw;
      right: -35vw;
      border: none;
      border-radius: 500px;
      width: 70vw;
      height: 70vw;

      svg {
        width: 44px;
        height: 44px;
        position: fixed;
        bottom: 30px;
        right: 20px;
      }
    }
  }
}

.loadingscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $white;
  opacity: 0;
  visibility: hidden;
  transition: 0.85s ease-in-out;
  font-size: 48px;
  color: $black;
  font-weight: 700;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 20px;

  span {
    opacity: 0;
    visibility: hidden;
    transition: 0.85s ease-in-out;
    transition-delay: 1s;
  }

  &.active {
    opacity: 1;
    visibility: visible;

    span {
      opacity: 1;
      visibility: visible;
    }
  }
}
</style>
